<template>
  <Main>
    <div class="client-detail" v-if="client != null">
      <div class="client-detail__container">
        <div class="client-detail__header">
          <div class="client-detail__button-back">
            <ButtonMain @click.native="backToList()" :buttonGrey="true">Назад к списку</ButtonMain>
          </div>
          <div class="client-detail__data">Дата регистрации: {{ dateTransform }}</div>
        </div>
        <div class="client-detail__button-group">
          <ButtonMain @click.native="toUpdateClient()" :buttonDark="true">Редактировать</ButtonMain>
          <ButtonMain @click.native="handleDeleteClient()" :buttonDark="true">Удалить</ButtonMain>
        </div>
        <div class="client-detail__info">
          <div class="client-detail__info-item">
            <ListItem :title="'Имя:'" :value="client.name" />
            <ListItem :title="'Телефон:'" :value="client.phone" />
          </div>
          <div class="client-detail__info-item">
            <ListItem :title="'Название компании:'" :value="client.companyName" />
            <ListItem :title="'Код ЕГРПОУ:'" :value="client.codeUsreou" />
          </div>
          <div class="client-detail__info-item">
            <ListItem :title="'Еmail:'" :value="client.email" />
          </div>
        </div>
        <CustomTable
          :data="clientOrder"
          :deleted-keys="tableOptions.deletedKeys"
          :rename-columns="tableOptions.renameColumn"
          :custom-order="tableOptions.customOrder"
          :is-action-block-visible="tableOptions.isActionBlockVisible"
          :direction="directionSort"
          :order="orderSort"
          :handleFilter="handleFilter"
          :name="'order'"
        />
      </div>
    </div>
    <DefaultModal
      class="clients__confirm-modal"
      v-if="deleteModal"
      :on-close="handleCloseConfirmModal"
    >
      <ConfirmModalContent :on-cancel="handleCloseConfirmModal" :on-success="handleDelete">
        <template v-slot:description>
          <p>Вы уверены, что хотите удалить заказчика?</p>
        </template>
        <template v-slot:cancel> Отмена </template>
        <template v-slot:success> Да </template>
      </ConfirmModalContent>
    </DefaultModal>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "../constants/routes";
import { dateTransform } from "../helpers/dateHelper";
import { ordersList } from "../helpers/dataIteration";

import Main from "../templets/Main.vue";
import FetchSpinnerModal from "../components/molecules/FetchSpinnerModal.vue";
import ListItem from "../components/molecules/ListItem.vue";
import ButtonMain from "../components/atoms/buttons/ButtonMain.vue";
import DefaultModal from "../components/molecules/DefaultModal.vue";
import ConfirmModalContent from "../components/molecules/ConfirmModalContent.vue";
import CustomTable from "../components/molecules/CustomTable.vue";

export default {
  name: "ClientDetail",
  data() {
    return {
      directionSort: null,
      orderSort: "",
      deleteModal: false,
      tableOptions: {
        deletedKeys: [""],
        isActionBlockVisible: true,
        onCustomStyleColumn: [
          {
            columnName: "address_from_short",
            className: "column-wrap",
          },
        ],
        renameColumn: [
          {
            from: "id",
            to: "ID",
          },
          {
            from: "address_from_short",
            to: "Маршрут",
          },
          {
            from: "driver_last_name",
            to: "Водитель",
          },
          {
            from: "driver_phone",
            to: "Телефон водителя",
          },
          {
            from: "date",
            to: "Дата",
          },
          {
            from: "time",
            to: "Время",
          },
          {
            from: "rate",
            to: "Тариф, грн",
          },
          {
            from: "total_price",
            to: "Цена, грн",
          },
          {
            from: "status",
            to: "Статус",
          },
        ],
        customOrder: [
          "id",
          "address_from_short",
          "driver_last_name",
          "driver_phone",
          "date",
          "time",
          "rate",
          "total_price",
          "status",
        ],
      },
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ListItem,
    ButtonMain,
    DefaultModal,
    ConfirmModalContent,
    CustomTable,
  },
  methods: {
    getClient() {
      const { params } = this.$route;
      const url = `/${params.clientId}`;
      this.$store.dispatch("clients/getClientDetail", url);
    },
    backToList() {
      this.$router.push({
        path: ROUTE.CLIENTS_PAGE.replace(":page", 1),
      });
    },
    toUpdateClient() {
      this.$router.push({
        path: ROUTE.CLIENT_CREATE_PAGE.replace(":process", "update").replace(
          ":clientId",
          this.client.id,
        ),
      });
    },
    handleDeleteClient() {
      this.deleteModal = true;
    },
    handleDelete() {
      const url = `/${this.client.id}`;
      this.$store.dispatch("clients/deleteClient", url).finally(() => {
        this.handleCloseConfirmModal();
        this.backToList();
      });
    },
    handleCloseConfirmModal() {
      this.deleteModal = false;
    },
    handleFilter(column) {
      const { params } = this.$route;
      this.orderSort = column;
      if (this.directionSort === null) {
        this.directionSort = true;
      } else {
        this.directionSort = !this.directionSort;
      }
      let url = `/${params.clientId}?order=${this.orderSort}&direction=${
        this.directionSort ? "asc" : "desc"
      }`;
      this.$store.dispatch("clients/getClientDetail", url);
    },
  },
  mounted() {
    this.getClient();
  },
  computed: {
    ...mapState("clients", ["error", "client", "loadingProcess"]),
    dateTransform() {
      return dateTransform(this.client.createdAt);
    },
    clientOrder() {
      return ordersList(this.client.orders);
    },
  },
};
</script>

<style lang="scss" scoped>
.client-detail {
  width: 100%;
  &__container {
    padding: 25px 17px;
  }

  &__header {
    @include flex-row;

    justify-content: space-between;
    padding-right: 70px;
    padding-bottom: 20px;
  }

  &__data {
    display: flex;
    align-items: flex-end;
    color: $mainSecondTextColor;
  }

  &__button-back {
    width: 140px;
  }

  &__button-group {
    @include flex-row;

    padding-bottom: 20px;

    /deep/.button-main.dark {
      width: 158px;
      margin-right: 20px;
    }
  }

  &__info {
    display: flex;
  }

  &__info-item {
    padding-right: 60px;
  }
}

/deep/.list-item__title {
  min-width: 200px;
}
/deep/.custom-table__header {
  grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
}
/deep/.custom-table__row {
  grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
}
</style>
